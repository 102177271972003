window.addEventListener('ga-cart.purchase', (e) => {
    let gtag = window.gtag || console.log;
    let { cart, order } = e.detail;

    // GA4 ecommerce products
    let itemsPurchased = _.chain(cart.items).get('data')
        .map((item, index) => {

            return {
                item_id: `${ _.get(item, 'attributes.price.code') }`,
                item_name: `${ _.get(item, 'attributes.product.name') }`,
                item_brand: `${ _.get(item, 'attributes.product.brand_name', 'jane iredale') }`,
                quantity: `${ _.get(item, 'attributes.quantity') }`,
                price: `${ Number(_.get(item, 'attributes.item_price')).toFixed(2) }`,
                index: index,
            }
        }).value();

    gtag('event', 'purchase', {
        transaction_id: `${ order.id }`,
        affiliation: `${ window.env['APP_NAME'] }`,
        value: `${ Number(_.get(order, 'attributes.amount_billed')).toFixed(2) }`,
        tax: `${ Number(_.get(order, 'attributes.gst')).toFixed(2) }`,
        shipping: `${ Number(_.get(order, 'attributes.freight_total')).toFixed(2) }`,
        currency: 'NZD',
        coupon: `${ _.get(order, 'attributes.discount_code', '') }`,
        // GA4 ecommerce products
        items: itemsPurchased,

        // UA format
        products: _.chain(cart.items).get('data')
            .map((item, index) => {

                return {
                    id: `${ _.get(item, 'attributes.price.code') }`,
                    name: `${ _.get(item, 'attributes.product.name') }`,
                    brand: `${ _.get(item, 'attributes.product.brand_name', 'jane iredale') }`,
                    quantity: `${ _.get(item, 'attributes.quantity') }`,
                    price: `${ Number(_.get(item, 'attributes.item_price')).toFixed(2) }`,
                    index: index,
                }
            }).value(),
    });

    // GTM dataLayer
    // dataLayer.push({
    //     'event': 'purchase',
    //     'ecommerce': {
    //         'transaction_id': `${ order.id }`,
    //         'affiliation': `${ window.env['APP_NAME'] }`,
    //         'value': `${ _.get(order, 'attributes.amount_billed') }`,
    //         'tax': `${ _.get(order, 'attributes.gst') }`,
    //         'shipping': `${ _.get(order, 'attributes.freight_total') }`,
    //         'currency': 'NZD',
    //         'coupon': `${ _.get(order, 'attributes.discount_code', '') }`,
    //         'items': itemsPurchased
    //     }
    // });
});